<template>
	<div class="page-container">
		<Head />
		<div v-if="slidePic&&slidePic.length>0" class="page-banner" :style="{backgroundImage:'url('+slidePic[0]+')'}">
			<div class="container banner-align-left">
				<div class="banner-text wow fadeInDown">
					<div class="ch">{{lang=='zh-cn'?slide.title:slide.title_en}}</div>
					<div class="en">{{lang=='zh-cn'?slide.titile_comment:slide.titile_comment_en}}}</div>
					<img src="../assets/image/down-arrow.png" alt="" />
				</div>
			</div>
		</div>
		<div class="page-wrapper">
			<Content :pagecontent="thispage.items"/>
		</div>
		<div class="about-section">
			<div v-if="lang=='zh-cn'" class="section-title wow fadeInDown">联系我们</div>
			<div v-else class="section-title wow fadeInDown">Contact Us</div>
			<div class="container wow fadeInDown">
				<el-row :gutter="20">
					<el-col :xs="12" :span="6">
						<div class="mp-card">
							<span class="iconfont icon-dianhua"></span>
							<div class="mp-label">{{company.tel1}}</div>
						</div>
					</el-col>
					<el-col :xs="12" :span="6">
						<div class="mp-card">
							<span class="iconfont icon-youxiang"></span>
							<div class="mp-label">{{company.email}}</div>
						</div>
					</el-col>
					<el-col :xs="24" :span="12">
						<div class="mp-card">
							<span class="iconfont icon-dizhi"></span>
							<div class="mp-label">{{lang=='zh-cn'?company.address:company.address_en}}</div>
						</div>
					</el-col>
				</el-row>
				<div class="map-panel wow fadeInDown">
					<!-- <baidu-map class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="true">
					</baidu-map> -->
					<baidu-map class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="true">
						<bm-marker :position="center" :dragging="true" >
							<bm-info-window :show="show" 
								style="font-size:13px">
								{{ company.enter_name }}
							</bm-info-window>
						</bm-marker>
					</baidu-map>
				</div>
				<div class="container wow fadeInDown words-box">
					<div class="msg-form">
						<h3 v-show="lang=='zh-cn'" class="msg-form-title">在线留言</h3>
						<h3 v-show="lang=='en-US'" class="msg-form-title">Online Message</h3>
						<el-form ref="form" :model="form">
							<el-form-item>
								<el-input v-model="form.name" :placeholder="placeholders.name"></el-input>
							</el-form-item>
							<el-form-item>
								<el-input v-model="form.phone" :placeholder="placeholders.phone"></el-input>
							</el-form-item>
							<el-form-item>
								<el-input v-model="form.email" :placeholder="placeholders.email"></el-input>
							</el-form-item>
							<el-form-item>
								<el-input type="textarea" v-model="form.requirement" :placeholder="placeholders.desc"></el-input>
							</el-form-item>
							<el-form-item class="sub-item">
								<el-button type="warning" @click="onSubmit" class="sub-btn">{{placeholders.submit}}</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
			
		</div>
		
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import {
		mapState
		} from 'vuex';
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import Content from '../components/content.vue';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			Content
		},
		data() {
			return {
				markerPoint: { 
					lng:  113.869387,
					lat:22.581494
				},
				center1: {
					lng:  113.869387,
					lat: 22.581494
				}, // 地图中心点
				zoom: 15, // 缩放级别
				show: true,
				banner: require('../assets/image/banner-about.jpg'),
				form: {
					name: '',
					phone: '',
					email: '',
					requirement: ''
				},
				placeholders:{},
				placeholders_cn:{
					name:'姓名',
					phone:'电话',
					email: '邮箱地址',
					desc: '请写下您的需求',
					submit:'提交',
				},
				placeholders_en:{
					name:'Your name',
					phone:'Your phone',
					email: 'Your email',
					desc: 'Please fill in what you need',
					submit:'Submit',
				},
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current,
				'en_id':state=>state.app.en_id
			}),
			company(){
				if(this.webcontents){
					return this.webcontents.base
				}else{
					return {}
				}
				// return this.webcontents.base
			},
			center(){
				if(this.company && this.company.lng){
					let mark = {
						lng:  this.company.lng,
						lat: this.company.lat
					}
					return mark
				}else{
					return this.center1	
				}
			},
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'ContactUs')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}		
				
			},
			slide(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0]:'';
			},			
			slidePic(){
				// var slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				if(this.slide){
					return this.slide.pictures.split(',');
				}else{
					return []
				}				
			},
			pageitems(){
				var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				var items = []
				var ob = {}
				var pic = []
				var title, ltitle, content
				for (var it of itemlist){
					if(it.item_type =='Three_column'){
						ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
						content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
					}else if(it.item_type !='picture' && it.item_type !='analysis'){
						if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
							content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
						}else if(it.content !='null'&&it.content){
							content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
						}else{
							content = ''
						}
					}else{
						content=this.lang == 'en-US'?it.content_en:it.content
					}
					if(it.pictures){
						pic=it.pictures.split(',')
					}
					title = this.lang == 'en-US'?it.title_en :it.title
					ob = {title:title,ltitle:ltitle,pic:pic,item_type:it.item_type,content:content}
					items.push(ob)
				}
				return items
			},
		},
		watch: {
			lang:{
				handler(val){
					if(val == 'zh-cn'){
						this.placeholders = this.placeholders_cn;
						this.messageok = '需求提交成功！'
					}else{
						this.placeholders = this.placeholders_en;
						this.messageok = 'success！'
					}
				},
				immediate: true
			}
			
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			infoWindowClose() {
				this.show = false
			},
			infoWindowOpen() {
				this.show = true
			},
			onSubmit() {
				console.log('submit!');
				if(this.placeholders.submit != "提交" &&this.placeholders.submit != "Submit"){return}
				this.placeholders.submit = "提交中...";
				this.form.en_id = this.en_id;
				var that= this;
				this.$axios.post("https://api.cheyway.com/api/website/post_meassage",this.form).then(res=>{
					console.log(res)
					this.$message({
						type: 'info',
						message: that.messageok
					});
					that.form = {};
					that.placeholders.submit =that.lang== 'zh-CN'?"提交":"Submit";
				})
			},
		},
		mounted() {
			new this.$wow.WOW({live: false}).init();
		}
	}
</script>
<style scoped>
	.page-container{
		background-color: #f5f7fd;
	}
</style>